import firebase from 'firebase';

// Your web app's Firebase configuration
let fireBaseConfig = {
    apiKey: "AIzaSyAkI-JSatlXpKbeYRpb6I1a4FgvqxudW4o",
    authDomain: "cveamls.firebaseapp.com",
    projectId: "cveamls",
    storageBucket: "cveamls.appspot.com",
    messagingSenderId: "285116200788",
    appId: "1:285116200788:web:6ff5ecb8cb9c414eea3ac1",
    measurementId: "G-J381WKV1JF"
};

// Initialize Firebase
firebase.initializeApp(fireBaseConfig);
